<template>
    <el-select
        ref="clientDropdown"
        v-model="selectedClient"
        :placeholder="$t('common.clients')"
        filterable
        :multiple="multiple"
        :class="customClass"
        :clearable="clearable"
        :collapse-tags="collapseTags"
        :filter-method="filterMethod"
        :disabled="disabled"
        class="client-dropdown"
        :popper-append-to-body="false"
        @change="selectClient"
        @visible-change="visibleChange"
    >
        <el-option-group :label="$t('common.active')">
            <el-option
                v-for="client in activeClients"
                :key="client.uuid"
                :label="client.name + ' ' + client.surname"
                :value="client.uuid"
            >
                <span>{{ client.name }} {{ client.surname }}</span>
                <span class="custom-id">{{ client.custom_id }}</span>
            </el-option>
        </el-option-group>

        <el-option-group v-if="withArchive" :label="$t('common.archived')">
            <el-option
                v-for="client in archivedClients"
                :key="client.uuid"
                :label="client.name + ' ' + client.surname"
                :value="client.uuid"
            />
        </el-option-group>
    </el-select>
</template>
<script>
import queryString from 'query-string';

export default {
    props: {
        withArchive: {
            type:    Boolean,
            default: false,
        },
        multiple: {
            type:    Boolean,
            default: false,
        },
        customClass: {
            type:    String,
            default: '',
        },
        collapseTags: {
            type:    Boolean,
            default: true,
        },
        clearable: {
            type:    Boolean,
            default: true,
        },
        withoutQuerySearch: {
            type:    Boolean,
            default: false,
        },
        disabled: {
            type:    Boolean,
            default: false,
        },
    },

    data() {
        return {
            selectedClient:        [],
            activeClientsOrigin:   [],
            archivedClientsOrigin: [],
            activeClients:         [],
            archivedClients:       [],
        };
    },

    computed: {
        activeClientsData() {
            return this.$store.state.clientsActiveDropdown;
        },
        archivedClientsData() {
            return this.$store.state.clientsArchivedDropdown;
        },
    },

    watch: {
        activeClientsData() {
            this.activeClients = this.activeClientsData;
            this.activeClientsOrigin = this.activeClientsData;
        },
        archivedClientsData() {
            this.archivedClients = this.archivedClientsData;
            this.archivedClientsOrigin = this.archivedClientsData;
        },
    },

    created() {
        this.activeClients = this.activeClientsData;
        this.activeClientsOrigin = this.activeClientsData;
        this.archivedClients = this.archivedClientsData;
        this.archivedClientsOrigin = this.archivedClientsData;
    },

    mounted() {
        this.$refs.clientDropdown.focus();

        this.$nextTick(() => {
            this.$refs.clientDropdown.blur();
            const query = queryString.parse(window.location.search, { sort: false });

            if (this.multiple) {
                query.client = Array.isArray(query.client) ? query.client : (query.client ? [query.client] : []);
                this.selectedClient = query.client;
                return;
            }

            // FIXME probably it has to be fixed, but it's used only in Employee/Details/Excludes
            query.client = query.client ? query.client : '';
            this.selectedClient = query.client;
        });
    },

    methods: {
        selectClient() {
            if (this.withoutQuerySearch) {
                this.$emit('selected', this.selectedClient);
                return;
            }

            const query = queryString.parse(window.location.search, { sort: false });
            query.client = this.selectedClient;

            this.$router.replace({
                query,
            }).catch(() => {});

            this.$emit('selected', this.selectedClient);
        },

        visibleChange() {
            this.activeClients = this.activeClientsOrigin;
        },

        filterMethod(query) {
            this.activeClients = this.activeClientsOrigin.filter(client => {
                return client.name.toLowerCase().includes(query.toLowerCase())
                || (client.surname && client.surname.toLowerCase().includes(query.toLowerCase()))
                // || (client.custom_id && client.custom_id.includes(query.toLowerCase()))
            });
        },

        resetSelection() {
            this.selectedClient = [];
        },
    },
};
</script>
<style>
    .custom-id {
        position: absolute;
        top: -11px;
        right: 5px;
        font-size: 11px;
        color: #cecece;
    }
    .el-select-dropdown.is-multiple .el-select-dropdown__item.selected::after {
        left: 3px;
    }
</style>
